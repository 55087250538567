/*
 * NOTA: As funções que verificam se a mídia é um audio, imagem ou video são provavelmente bem
 * amadoras, mas era dessa forma que estava funcionando no sistema antigo.
 */
import { isNotBlank } from './string-utils';
export var audioTypes = ['mp3', 'wma', 'oga', 'm4a', 'wav'];
export var thumbnailTypes = ['thumb', 'thumbnail']; // Stratus, Axle AI
export var imageTypes = [
    'png',
    'bmp',
    'jpg',
    'jpeg',
    'gif',
    // especial para techDescription
    'image',
];
export var videoTypes = [
    'mp4',
    'ogv',
    'ogg',
    '3gp',
    'webm',
    'm4v',
    'mpeg',
    'mpg',
    'wmv',
    'flv',
    'avi',
    'mov',
    // especial para techDescription
    'video',
    'proxy',
    'qt', // Stratus
];
export function containsExtension(path, extensions) {
    var url = (path || '').toLowerCase();
    return !!extensions.find(function (ext) { return url.indexOf("." + ext) !== -1; });
}
export function containsType(type, types) {
    var desc = (type || '').toLowerCase();
    return !!types.find(function (t) { return desc.indexOf(t) !== -1; });
}
export function hasAudioExtension(path) {
    return containsExtension(path, audioTypes);
}
export function hasImageExtension(path) {
    return containsExtension(path, imageTypes);
}
export function hasVideoExtension(path) {
    return containsExtension(path, videoTypes);
}
export function isAudioType(type) {
    return containsType(type, audioTypes);
}
export function isImageType(type) {
    return containsType(type, imageTypes);
}
export function isThumbnailType(type) {
    return containsType(type, thumbnailTypes);
}
export function isVideoType(type) {
    return containsType(type, videoTypes);
}
export function typeMatch(a, b) {
    // Verifica se os valores são iguais
    if (a === b || (a || '').toLowerCase() === (b || '').toLowerCase()) {
        return true;
    }
    // Verifica se tem um valor dentro do outro
    if (a && b && isNotBlank(a) && isNotBlank(b)) {
        return a.indexOf(b) !== -1 || b.indexOf(a) !== -1;
    }
    return false;
}
