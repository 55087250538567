import { call, put, takeLatest } from 'redux-saga/effects'

import { reportageApi as api } from '@anews/api'
import { Reportage, ReportageConfig, Page } from '@anews/types'

import { isNew } from '@anews/utils'

import {
  EntityLockActions,
  NotificationActions,
  ReportageActionType as ActionType,
  ReportageActionMap,
  ReportageActions,
} from '../actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { renewLock } = EntityLockActions
const { notifyError } = NotificationActions

const {
  filterReportagesSuccess,
  filterReportagesFailure,
  loadReportageSuccess,
  loadReportageFailure,
  createReportageSuccess,
  createReportageFailure,
  updateReportageSuccess,
  updateReportageFailure,
  removeReportagesSuccess,
  removeReportagesFailure,
  loadConfigSuccess,
  loadConfigFailure,
  updateConfigSuccess,
  updateConfigFailure,
  createConfigSuccess,
  createConfigFailure,
  createReportageFromGuidelineSuccess,
  createReportageFromGuidelineFailure,
  patchReportageSuccess,
  patchReportageFailure,
  changeStateReportageSuccess,
  changeStateReportageFailure,
  copyReportagesSuccess,
  copyReportagesFailure,
} = ReportageActions
/* Watchers */

function* filterReportagesSaga(action: ReportageActionMap<ActionType.FILTER_REQUEST>): Generator {
  try {
    const result = yield call(api.pageFilter, action.filter, action.page)
    yield put(filterReportagesSuccess(result as Page<Reportage>))
  } catch (error) {
    yield put(filterReportagesFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* loadReportageSaga(action: ReportageActionMap<ActionType.LOAD_REQUEST>): Generator {
  try {
    if (typeof action.target === 'number') {
      const reportage = yield call(api.load, action.target, action.edit)
      yield put(loadReportageSuccess(reportage as Reportage, action.edit))
    } else {
      const reportage = action.target
      if (!isNew(reportage)) {
        yield put(renewLock(reportage.uuid))
      }
      yield put(loadReportageSuccess(reportage, action.edit))
    }
  } catch (error) {
    yield put(loadReportageFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createReportageSaga(action: ReportageActionMap<ActionType.CREATE_REQUEST>): Generator {
  try {
    const reportage = yield call(api.create, action.reportage, action.lock)
    yield put(createReportageSuccess(reportage as Reportage))
  } catch (error) {
    yield put(createReportageFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* createReportageFromGuidelineSaga(
  action: ReportageActionMap<ActionType.CREATE_FROM_GUIDELINE_REQUEST>,
): Generator {
  try {
    const reportage = yield call(api.createFromGuideline, action.guidelineId)
    yield put(createReportageFromGuidelineSuccess(reportage as Reportage))
  } catch (error) {
    yield put(createReportageFromGuidelineFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateReportageSaga(action: ReportageActionMap<ActionType.UPDATE_REQUEST>): Generator {
  try {
    const reportage = yield call(api.update, action.reportage)
    yield put(updateReportageSuccess(reportage as Reportage))
  } catch (error) {
    yield put(updateReportageFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* patchReportageSaga(action: ReportageActionMap<ActionType.PATCH_REQUEST>): Generator {
  try {
    yield call(api.updateField, action.reportageId, action.field, action.newValue)
    yield put(patchReportageSuccess())
  } catch (error) {
    yield put(
      patchReportageFailure(
        error,
        action.reportageId,
        action.field,
        action.newValue,
        action.oldValue,
      ),
    )
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* changeStateReportageSaga(
  action: ReportageActionMap<ActionType.CHANGE_STATE_REQUEST>,
): Generator {
  try {
    yield call(api.updateState, action.reportageIds, action.state)
    yield put(changeStateReportageSuccess())
  } catch (error) {
    yield put(changeStateReportageFailure(error, action.reportageIds, action.state))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* removeReportagesSaga(action: ReportageActionMap<ActionType.REMOVE_REQUEST>): Generator {
  try {
    const ids = yield call(api.remove, action.ids)
    yield put(removeReportagesSuccess(ids as number[]))
  } catch (error) {
    yield put(removeReportagesFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

function* copyReportagesSaga(action: ReportageActionMap<ActionType.COPY_REQUEST>): Generator {
  try {
    const reportages = yield call(api.copy, action.ids, action.programsIds, action.date)
    yield put(copyReportagesSuccess(reportages as Reportage[]))
  } catch (error) {
    yield put(copyReportagesFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}
//
// Config
//

function* loadConfigSaga(): Generator {
  try {
    const config = yield call(api.loadConfig)
    yield put(loadConfigSuccess(config as ReportageConfig))
  } catch (error) {
    yield put(loadConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createConfigSaga(
  action: ReportageActionMap<ActionType.CREATE_CONFIG_REQUEST>,
): Generator {
  try {
    const config = yield call(api.createConfig, action.config)
    yield put(createConfigSuccess(config as ReportageConfig))
  } catch (error) {
    yield put(createConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateConfigSaga(
  action: ReportageActionMap<ActionType.UPDATE_CONFIG_REQUEST>,
): Generator {
  try {
    const config = yield call(api.updateConfig, action.config)
    yield put(updateConfigSuccess(config as ReportageConfig))
  } catch (error) {
    yield put(updateConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.FILTER_REQUEST, filterReportagesSaga)
  },
  function* () {
    yield takeLatest(ActionType.LOAD_REQUEST, loadReportageSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_REQUEST, createReportageSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_FROM_GUIDELINE_REQUEST, createReportageFromGuidelineSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_REQUEST, updateReportageSaga)
  },
  function* () {
    yield takeLatest(ActionType.PATCH_REQUEST, patchReportageSaga)
  },
  function* () {
    yield takeLatest(ActionType.CHANGE_STATE_REQUEST, changeStateReportageSaga)
  },
  function* () {
    yield takeLatest(ActionType.REMOVE_REQUEST, removeReportagesSaga)
  },
  function* () {
    yield takeLatest(ActionType.COPY_REQUEST, copyReportagesSaga)
  },
  function* () {
    yield takeLatest(ActionType.LOAD_CONFIG_REQUEST, loadConfigSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_CONFIG_REQUEST, createConfigSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_CONFIG_REQUEST, updateConfigSaga)
  },
])
