/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { v4 as uuidV4 } from 'uuid'
import moment from 'moment'
import isEqual from 'react-fast-compare'
import produce from 'immer'

import Alert from 'antd/es/alert'
import Button from 'antd/es/button'
import Divider from 'antd/es/divider'
import Dropdown from 'antd/es/dropdown'
import Form from 'antd/es/form'
import Menu from 'antd/es/menu'
import message from 'antd/es/message'
import Collapse from 'antd/es/collapse'
import { Store } from 'antd/es/form/interface'

import { DownOutlined } from '@ant-design/icons'

import { connect } from 'react-redux'

import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'

import { Tooltip } from 'antd/es'

import {
  APPEARANCE,
  ART,
  HEADING,
  INFORMATION,
  INTERVIEW,
  NOTE,
  OFF,
  SOUND_UP,
  TEASER,
  TEXT,
  NEWSPAPER,
  RADIO,
  TV,
  WEB,
  Reportage,
  ReportageConfig,
  ReportageSection,
  DocumentConfig,
  EntityLock,
  ReportageRevision,
  ROLE_NEWS_REPORT_ADMIN,
  Device,
  IITemplate,
  ReportageCG,
  DeviceProtocol,
  RichTextBlock,
} from '@anews/types'

import {
  toggleCase,
  dateToMoment,
  momentToDate,
  isNew,
  sumDurations,
  formatIsoDuration,
  richToPlain,
  toggleRichFormat,
} from '@anews/utils'

import { reportageApi, programApi } from '@anews/api'

import { useInterval, useOnce, useUnload } from '@anews/hooks'

import { useTranslation } from '../../../i18n'
import { print, REPORTAGE_CONTENT } from '../../../print'

import { MosPluginActions } from '../../../redux/actions/mosplugin-actions'

import { PropsMapper, RootState } from '../../../redux/reducers'
import { ClassificationsListState } from '../../../redux/reducers/classifications-reducer'
import { CompaniesListState } from '../../../redux/reducers/companies-reducer'
import { EditorialsListState } from '../../../redux/reducers/editorials-reducer'
import { ProgramsListState } from '../../../redux/reducers/programs-reducer'
import { UsersListState } from '../../../redux/reducers/users-reducer'
import { VehiclesListState } from '../../../redux/reducers/vehicles-reducer'
import { createRolesChecker } from '../../../redux/selectors/auth-selectors'

import { calculateReadDuration } from '../../../utils/user-utils'
import { CHECK_INTERVAL, Section, useUnsavedDocument } from '../../../unsaved'
import { reportageChanged } from '../../../unsaved/comparators/reportageChanged'

import { HGroup, ScrollContainer, YesNoCancel, CarouselModal } from '../../global'

import SelectCGTemplateModal from '../cg/SelectCGTemplateModal'
import RevisionCard from '../revision/RevisionCard'

import { ItemReceivedHandler } from '../../../redux/reducers/mosplugin-reducer'

import { itemToMosData, mosDataToItem } from '../../../mos/reportage-helper'

import theme from '../../../theme'

import {
  generateReportageCgItemId,
  mosReportageId,
  mosReportagesRoId,
} from '../../../utils/mos-utils'

import { useFormShortcuts } from '../../../hooks/useFormShortcuts'

import ReportageFormHeader from './ReportageFormHeader'
import ReportageFormBody from './ReportageFormBody'
import ReportageHistoryDrawer from './ReportageHistoryDrawer'
import ReportageCGForm from './ReportageCGForm'

const { Panel } = Collapse

const ButtonBar = styled(HGroup)`
  border-bottom: 1px solid ${theme.border};
  background-color: ${theme.backgrounds.light};
`

const BarButton = styled(Button)`
  &.ant-btn {
    border: 0;
    border-radius: 0 !important;
    background-color: ${theme.backgrounds.light};
  }
  &.ant-btn:hover {
    background-color: ${theme.backgrounds.base};
  }
`

const bodyStyle = { margin: '16px 8px' }

const CGS_KEY = 'cgs'

const textSections: {
  [key: string]: 'headingSuggestion' | 'information' | 'noteSuggestion' | 'teaser' | 'text'
} = {
  [HEADING]: 'headingSuggestion',
  [INFORMATION]: 'information',
  [NOTE]: 'noteSuggestion',
  [TEASER]: 'teaser',
  [TEXT]: 'text',
}

const removeCG = (cgs: ReportageCG[], initIndex: number): ReportageCG[] => {
  const firstPart: ReportageCG[] = cgs.slice(0, initIndex)
  const secondPart: ReportageCG[] = cgs
    .slice(initIndex + 1, cgs.length)
    .map(cg => ({ ...cg, index: cg.index - 1 }))

  return [...firstPart, ...secondPart]
}

const insertCG = (
  cgs: ReportageCG[],
  insertIndex: number,
  copyFrom: ReportageCG,
): ReportageCG[] => {
  const firstPart: ReportageCG[] = cgs.slice(0, insertIndex)

  const newCG = {
    ...copyFrom,
    index: insertIndex,
    uuid: uuidV4(),
    id: 0,
    fields: copyFrom.ciiData?.fields.map(field => ({
      ...field,
      uuid: uuidV4(),
      id: 0,
      propertyUuid: uuidV4(),
      propertyId: 0,
    })),
  }

  firstPart.push(newCG)

  const secondPart: ReportageCG[] = cgs
    .slice(insertIndex, cgs.length)
    .map(cg => ({ ...cg, order: cg.index + 1 }))

  return [...firstPart, ...secondPart]
}

interface OwnProps {
  reportage: Reportage
  lock?: EntityLock
  userNickname?: string
  documentConfig?: DocumentConfig
  reportageConfig?: ReportageConfig
  classifications: ClassificationsListState
  companies: CompaniesListState
  editors: UsersListState
  editorials: EditorialsListState
  accessiblePrograms: ProgramsListState
  reporters: UsersListState
  vehicles: VehiclesListState
  saving: boolean
  activeTab?: string
  listReporters?: () => void
  onSave: (reportage: Reportage, lock: boolean) => void
  onClose: (uuid: string) => void
  onChange: (reportage: Reportage) => void
}

const mapStateToProps = (state: RootState) => ({
  hasRoles: createRolesChecker(state),
})

const dispatchProps = {
  openPlugin: MosPluginActions.open,
  editInPlugin: MosPluginActions.edit,
  updateRef: MosPluginActions.updateRef,
}

type Props = PropsMapper<typeof mapStateToProps, typeof dispatchProps> & OwnProps

function ReportageForm({
  reportage,
  lock,
  classifications,
  companies,
  documentConfig,
  reportageConfig,
  editors,
  editorials,
  accessiblePrograms,
  reporters,
  vehicles,
  userNickname,
  saving,
  activeTab,
  openPlugin,
  editInPlugin,
  updateRef,
  listReporters,
  onSave,
  onClose,
  onChange,
  hasRoles,
}: Props) {
  const { t } = useTranslation()

  const canAdmin = hasRoles(ROLE_NEWS_REPORT_ADMIN)

  const [reporterId, setReporterId] = useState(reportage.reporterId)
  const [vehiclesIds, setVehiclesIds] = useState(reportage.vehiclesIds || [])
  const [sections, setSections] = useState(reportage.sections || [])
  const [initial, setInitial] = useState<Reportage | undefined>(reportage)
  const [visibleTexts, setVisibleTexts] = useState<{ [key: string]: boolean }>({})
  const [ok, setOk] = useState(false)
  const [confirmVisible, setConfirmVisible] = useState(false)

  const [form] = Form.useForm()
  const { getFieldValue, getFieldsValue, setFieldsValue, validateFields } = form

  const { checkChanged, clearCache } = useUnsavedDocument<Reportage>(
    Section.REPORTAGES,
    reportage.uuid,
  )

  useOnce(() => {
    listReporters && listReporters()
  })

  // Faz o unlock da reportagem quando o componente é destruido
  // Útil para quando fecha a área de reportagens com form aberto
  useUnload(() => onClose(reportage.uuid))

  useEffect(() => {
    setInitial(reportage)

    // Seções de texto simples visíveis
    const initialVisibleTexts: { [key: string]: boolean } = {}
    for (const [sectionConst, sectionProp] of Object.entries(textSections)) {
      initialVisibleTexts[sectionConst] = !!reportage[sectionProp]
    }
    setVisibleTexts(initialVisibleTexts)

    setReporterId(reportage.reporterId)
    setVehiclesIds(reportage.vehiclesIds || [])
    setSections(reportage.sections!)
    setCgs(reportage.cgs || [])
    setOk(reportage.ok)

    setFieldsValue({
      // Header fields
      date: moment.isMoment(reportage.date) ? reportage.date : dateToMoment(reportage.date),
      slug: reportage.slug,
      reporterId: reportage.reporterId,
      programsIds: reportage.programsIds,
      editorId: reportage.editorId,
      editorialsIds: reportage.editorialsIds,
      vehiclesIds: reportage.vehiclesIds,
      cameraman: reportage.cameraman,
      classificationId: reportage.classificationId,
      branchId: reportage.branchId,
      lead: reportage.lead,
      text: reportage.text,
      // Body fields
      information: reportage.information,
      headingSuggestion: reportage.headingSuggestion,
      noteSuggestion: reportage.noteSuggestion,
      teaser: reportage.teaser,
      // Sections são manipuladas manualmente via useState()
      // sections: reportage.sections,
    })
  }, [reportage, setFieldsValue])

  useEffect(() => {
    async function loadSavedData() {
      if (reportage.id > 0) {
        reportageApi.load(reportage.id, false).then(setInitial).catch(console.error)
      }
    }
    loadSavedData()
  }, [reportage.id])

  const reporter = useMemo(() => reporters.data.find(u => u.id === reporterId), [
    reporterId,
    reporters.data,
  ])

  // Recalcula o tempo de leitura quando houver alterações
  useEffect(() => {
    const updatedSections = produce(sections, draft => {
      for (let idx = 0; idx < draft.length; idx += 1) {
        const section: ReportageSection = draft[idx]
        if (!section.manual && section.type !== ART && section.type !== SOUND_UP) {
          const text = section.type !== INTERVIEW ? section.content : section.observation
          const plain: string = richToPlain(text)
          section.duration = calculateReadDuration(reporter, plain)
        }
      }
    })

    setSections(updatedSections)
  }, [reporter, sections])

  // Calcula o tempo total da reportagem toda vez que as seções mudarem
  const total = useMemo(() => {
    const sum = sections
      .filter(s => s.duration)
      .map(s => s.duration!)
      .reduce((prev, curr) => sumDurations([prev, curr]), 'PT0S')

    return formatIsoDuration(sum, 'mm:ss')
  }, [sections])

  //
  //  Manipulação das seções
  //

  const addSection = useCallback(type => {
    setSections(prevSections => [
      ...prevSections.map((s, index) => ({ ...s, index })),
      {
        type,
        index: prevSections.length,
        uuid: uuidV4(),
        duration: 'PT0S',
        manual: type === INTERVIEW,
        approved: false,
      } as ReportageSection,
    ])
  }, [])

  const toggleSectionCase = useCallback((uuid: string, prop: 'content' | 'observation') => {
    setSections(prevSections =>
      prevSections.map(s =>
        s.uuid !== uuid ? s : { ...s, [prop]: toggleRichFormat(s[prop], 'upperCase') },
      ),
    )
  }, [])

  const removeSection = useCallback((uuid: string) => {
    setSections(prevSections =>
      prevSections.reduce((sects, section) => {
        if (section.uuid !== uuid) {
          sects.push({ ...section, index: sects.length })
        }
        return sects
      }, new Array<ReportageSection>()),
    )
  }, [])

  const onMoveSectionDown = useCallback((uuid: string) => {
    setSections(prevSections => {
      const from = prevSections.findIndex(s => s.uuid === uuid)
      if (from < 0 || from >= prevSections.length - 1) {
        return prevSections
      }
      const to = from + 1
      const ps = [...prevSections]
      ps[to] = { ...ps[to], index: from }
      const tg = { ...ps.splice(from, 1)[0], index: to }
      ps.splice(to, 0, tg)
      return ps
    })
  }, [])

  const onMoveSectionUp = useCallback((uuid: string) => {
    setSections(prevSections => {
      const from = prevSections.findIndex(s => s.uuid === uuid)
      if (from <= 0) {
        return prevSections
      }
      const to = from - 1
      const ps = [...prevSections]
      ps[to] = { ...ps[to], index: from }
      const tg = { ...ps.splice(from, 1)[0], index: to }
      ps.splice(to, 0, tg)
      return ps
    })
  }, [])

  const onDragSection = useCallback(
    (uuid: string, sourceIndex: number, destinationIndex: number) => {
      setSections(prevSections => {
        if (sourceIndex === destinationIndex) {
          return prevSections
        }

        const result = [...prevSections]
        const [removed] = result.splice(sourceIndex, 1)
        result.splice(destinationIndex, 0, removed)

        // Atualiza os indices das sections
        result.forEach((element, index, array) => {
          const curr = result.findIndex(s => s.uuid === element.uuid)
          result[curr] = { ...result[curr], index }
        })

        return result
      })
    },
    [],
  )

  const sectionChange = useCallback((section: ReportageSection) => {
    setSections(prevSections => prevSections.map(s => (s.uuid !== section.uuid ? s : section)))
  }, [])

  const updateSectionDuration = useCallback(
    (uuid: string, manual: boolean, duration: string, text: RichTextBlock[] | undefined) => {
      setSections(prevSections =>
        prevSections.map(section => {
          if (section.uuid !== uuid) {
            return section
          }

          const manualChanged = manual !== section.manual
          const durationChanged = duration !== section.duration

          /*
           * Existem 3 cenários:
           * - mudou para manual -> seta duração PT0S
           * - mudou para auto -> calcula duração
           * - tá manual e alterou duração -> atualiza duração
           */

          let newDuration = 'PT0S'

          if (manualChanged) {
            if (!manual) {
              const plain = richToPlain(text)
              newDuration = calculateReadDuration(reporter, plain)
            }
          } else {
            if (!durationChanged) {
              return section
            }
            newDuration = duration
          }
          return { ...section, manual, duration: newDuration }
        }),
      )
    },
    [reporter],
  )

  //
  //  Manipulação dos GCs
  //

  const [cgs, setCgs] = useState(reportage.cgs || [])
  const [showTemplateModal, setShowTemplateModal] = useState(false)
  const [selectedCgDevice, setSelectedCgDevice] = useState<Device>()
  const [visibleCgs, setVisibleCgs] = useState(false)

  // Quando não tem GCs, ao adicionar um deixa o collapse aberto
  const defaultKey = !reportage.cgs || reportage.cgs.length === 0 ? CGS_KEY : undefined

  const programsChanged = useCallback(async (programsIds: number[]) => {
    if (programsIds.length !== 1) {
      setVisibleCgs(false)
      return
    }

    let hasCG = false

    try {
      const cg = await programApi.cg(programsIds[0])
      if (cg && (cg.protocol === DeviceProtocol.II || cg.plugin)) {
        hasCG = true
        setSelectedCgDevice(cg)
      }
    } catch (error) {
      console.error(error)
    }

    setVisibleCgs(hasCG)
  }, [])

  const onAddMosCG = useCallback<ItemReceivedHandler>(
    (item, handlerId, type, device) => {
      if (type !== 'CG') {
        message.warn(t('reportage:onlyCgAllowed'))
        return
      }
      setCgs(current => {
        const newCg: ReportageCG = {
          id: 0,
          uuid: uuidV4(),
          index: current.length,
          deviceId: (device || selectedCgDevice)?.id,
          mosData: itemToMosData(item),
        }

        return [...current, newCg]
      })
    },
    [selectedCgDevice, t],
  )

  const openCGSelection = useCallback(async () => {
    const cg = selectedCgDevice

    if (cg) {
      const { protocol, plugin, url } = cg
      if (protocol === DeviceProtocol.II) {
        setShowTemplateModal(true)
        return
      }
      if (protocol === DeviceProtocol.MOS) {
        if (plugin === 'HTML' && url) {
          openPlugin(
            cg,
            mosReportagesRoId(),
            mosReportageId(reportage.id),
            '',
            reportage.uuid,
            onAddMosCG,
          )
          return
        }
      }
      /* eslint-disable no-console */
      console.info('Could not handle CG device', { protocol, plugin, url })
    }
  }, [selectedCgDevice, openPlugin, reportage.id, reportage.uuid, onAddMosCG])

  useEffect(() => {
    updateRef(reportage.uuid, onAddMosCG)

    return () => {
      updateRef(reportage.uuid, undefined)
    }
  }, [updateRef, reportage.uuid, onAddMosCG])

  const onDuplicateCG = useCallback((duplicateIndex: number) => {
    setCgs(current => insertCG(current, current.length, current[duplicateIndex]))
  }, [])

  const onRemoveCG = useCallback((index: number) => {
    setCgs(current => removeCG(current, index))
  }, [])

  const onItemReceived = useCallback<ItemReceivedHandler>(
    (item, handlerId, type, device, isNewItem) => {
      if (type !== 'CG') {
        message.warn(t('reportage:onlyCgAllowed'))
        return
      }
      if (isNewItem) {
        onAddMosCG(item, handlerId, type, device)
      } else {
        setCgs(curr =>
          curr.map(cg => (cg.uuid !== handlerId ? cg : { ...cg, mosData: itemToMosData(item) })),
        )
      }
    },
    [onAddMosCG, t],
  )

  const onEditCG = useCallback(
    (cg: ReportageCG) => {
      if (!cg.mosData) {
        console.error('CG MOS data is undefined')
        return
      }
      if (!selectedCgDevice || selectedCgDevice.id !== cg.deviceId) {
        message.warn(t('reportage:editCgInvalid'))
        return
      }
      editInPlugin(
        selectedCgDevice,
        mosReportagesRoId(),
        mosReportageId(reportage.id),
        '',
        mosDataToItem(cg.mosData, generateReportageCgItemId(cg)),
        cg.uuid,
        onItemReceived,
      )
    },
    [selectedCgDevice, reportage.id, editInPlugin, onItemReceived, t],
  )

  const onMoveCG = useCallback((movedIndex: number, moveUp: boolean) => {
    setCgs(current => {
      if (moveUp && movedIndex === 0) {
        return current
      }

      if (!moveUp && movedIndex === current.length - 1) {
        return current
      }

      const updated = [...current]

      if (moveUp) {
        let aux = current[movedIndex - 1]
        aux = { ...aux, index: aux.index + 1 }

        updated[movedIndex] = { ...updated[movedIndex], index: updated[movedIndex].index - 1 }
        updated[movedIndex - 1] = updated[movedIndex]

        updated[movedIndex] = aux
      } else {
        let aux = current[movedIndex + 1]
        aux = { ...aux, index: aux.index - 1 }

        updated[movedIndex] = { ...updated[movedIndex], index: updated[movedIndex].index + 1 }
        updated[movedIndex + 1] = updated[movedIndex]

        updated[movedIndex] = aux
      }

      return updated
    })
  }, [])

  const onAddIICG = useCallback(
    (templates: IITemplate[]) => {
      setCgs(current => {
        const lastIndex = current.length

        const newCgs: ReportageCG[] = templates.map((template, idx) => ({
          id: 0,
          uuid: uuidV4(),
          index: lastIndex + idx,
          deviceId: selectedCgDevice?.id,
          ciiData: {
            uuid: uuidV4(),
            templateCode: template.code,
            templateName: template.name,
            fields: template.fields.map(field => ({
              uuid: uuidV4(),
              name: field.name,
              number: field.number,
              size: field.size,
              value: '',
            })),
          },
        }))

        return [...current, ...newCgs]
      })
    },
    [selectedCgDevice],
  )

  const onFieldChangeCG = useCallback((newValue: string, fieldIndex: number, cgIndex: number) => {
    setCgs(curr =>
      produce(curr, draft => {
        draft.forEach((cg, i) => {
          if (i === cgIndex) {
            cg.ciiData?.fields.forEach((field, j) => {
              if (j === fieldIndex) {
                field.value = newValue
              }
            })
          }
        })
      }),
    )
  }, [])

  const cgDragEndHandler = useCallback(({ source, destination }: DropResult) => {
    // Soltou fora de uma lista
    if (!destination) {
      return
    }

    setCgs(current => {
      if (source.index === destination.index) {
        return current
      }

      const result = [...current]
      const [removed] = result.splice(source.index, 1)
      result.splice(destination.index, 0, removed)

      // Atualiza os indices dos CGs
      result.forEach((element, index, array) => {
        const curr = result.findIndex(s => s.uuid === element.uuid)
        result[curr] = { ...result[curr], index }
      })

      return result
    })
  }, [])

  useEffect(() => {
    if (reportage.programsIds) {
      programsChanged(reportage.programsIds)
    }
  }, [reportage.programsIds, programsChanged])

  //
  //  Manipulação dos textos simples
  //

  const addText = useCallback(
    (type: string) => {
      setVisibleTexts({ ...visibleTexts, [type]: true })
    },
    [visibleTexts],
  )

  const toggleTextCase = useCallback(
    (sectionConst: string) => {
      const sectionProp = textSections[sectionConst]
      const value = getFieldValue(sectionProp)
      setFieldsValue({ [sectionProp]: toggleCase(value) })
    },
    [getFieldValue, setFieldsValue],
  )

  const removeText = useCallback(
    (sectionConst: string) => {
      const sectionProp = textSections[sectionConst]

      setFieldsValue({ [sectionProp]: undefined })
      setVisibleTexts({ ...visibleTexts, [sectionConst]: false })
    },
    [setFieldsValue, visibleTexts],
  )

  const approval = useCallback(
    async (status: boolean) => {
      try {
        await reportageApi.approval(reportage.id, status)
        setOk(status)
      } catch (error) {
        console.error(error)
      }
    },
    [reportage],
  )

  const menuClick = useCallback(
    ({ key }) => {
      switch (key) {
        case 'one-column':
        case 'two-columns':
          print(REPORTAGE_CONTENT, { ids: [reportage.id], twoColumns: key === 'two-columns' })
          break
        case 'approve':
          approval(true)
          break
        case 'disapprove':
          approval(false)
          break
        default:
        // nada
      }
    },
    [approval, reportage.id],
  )

  const buildReportage = useCallback(
    values => {
      // "date" é um objeto moment... tem que virar string
      const { date, slug, ...rest } = values

      // não recupera os textos simples porque senão não vai deixar apagar... deixa o que vier de values
      const { headingSuggestion, information, noteSuggestion, teaser, text, ...current } = reportage

      return {
        ...current,
        ...rest,
        slug: slug?.toUpperCase(), // Retranca sempre Uppercase
        date: momentToDate(date),
        sections,
        cgs,
        ok,
      }
    },
    [reportage, sections, cgs, ok],
  )

  const checkFormChanged = useCallback(
    () => checkChanged(buildReportage(getFieldsValue()), initial, reportageChanged),
    [buildReportage, checkChanged, initial, getFieldsValue],
  )

  useEffect(() => {
    if (onChange) {
      const data = buildReportage(getFieldsValue())
      onChange(data)
    }
  }, [buildReportage, getFieldsValue, onChange])

  useInterval(() => {
    checkFormChanged()
  }, CHECK_INTERVAL)

  //
  //  Submit
  //

  const finishHandler = useCallback(
    (values: Store | undefined, lockNew: boolean = true) => {
      clearCache()
      onSave(buildReportage(values || getFieldsValue()), lockNew)
    },
    [onSave, buildReportage, clearCache, getFieldsValue],
  )

  const finishFailedHandler = useCallback(() => {
    message.warning(t('validation:failed'))
  }, [t])

  const checkBeforeClose = useCallback(() => {
    if (checkFormChanged()) {
      setConfirmVisible(true)
    } else {
      clearCache()
      onClose(reportage.uuid)
    }
  }, [checkFormChanged, clearCache, reportage, onClose])

  useFormShortcuts({
    onSave: () => form.submit(),
    onClose: checkBeforeClose,
    isActive: reportage.uuid === activeTab,
  })

  const confirmNoHandler = useCallback(() => {
    setConfirmVisible(false)
    clearCache()
    onClose(reportage.uuid)
  }, [clearCache, reportage, onClose])

  const confirmYesHandler = useCallback(() => {
    setConfirmVisible(false)
    validateFields().then(values => {
      finishHandler(values, false)
      onClose(reportage.uuid)
    })
  }, [finishHandler, validateFields, onClose, reportage])

  //
  // Revisions
  //

  const [versionsVisible, setVersionsVisible] = useState(false)
  const [reportageVersions, setReportageVersions] = useState<ReportageRevision[]>([])
  const [historyVisible, setHistoryVisible] = useState(false)

  const versionsCards = useMemo(
    () =>
      reportageVersions.map(version => (
        <RevisionCard
          key={version.id}
          date={version.revisionDate}
          ip={version.revisorIP}
          nickname={version.revisorNickname}
          slug={version.slug}
          content={version}
        />
      )),
    [reportageVersions],
  )

  const versionsHandler = useCallback(async () => {
    if (reportage.id) {
      try {
        const result = await reportageApi.loadChangesHistory(reportage.id)
        setReportageVersions(result)
        setVersionsVisible(true)
      } catch (error) {
        console.error(error)
        message.error(t('error:loadFailed'))
      }
    }
  }, [reportage, t])

  //
  //  Lógica relacionada a veículos
  //

  const [textType, setTextType] = useState(false)
  const [tvType, setTvType] = useState(false)
  const [radioType, setRadioType] = useState(false)

  useEffect(() => {
    setTvType(false)
    setTextType(false)
    setRadioType(false)

    // Se "Veículos" estiver ativo, verifica quais estão selecionados para apresentar seus campos
    if (reportageConfig && reportageConfig.vehiclesEnabled) {
      for (const id of vehiclesIds) {
        for (const vehicle of vehicles.data) {
          if (id === vehicle.id) {
            if (vehicle.type === RADIO) {
              setRadioType(true)
            }
            if (vehicle.type === TV) {
              setTvType(true)
            }
            if (vehicle.type === WEB || vehicle.type === NEWSPAPER) {
              setTextType(true)
            }
          }
        }
      }
    } else {
      // Se não estiver utilizando o campo "Veículos" apresenta apenas campos de TV
      setTvType(true)
      setTextType(false)
      setRadioType(false)
    }
  }, [reportageConfig, vehicles.data, vehiclesIds])

  //
  //  Rendenização
  //

  const visibleProps = {
    showHeading: visibleTexts[HEADING],
    showInformation: visibleTexts[INFORMATION],
    showNote: visibleTexts[NOTE],
    showTeaser: visibleTexts[TEASER],
    showText: textType,
    tvType,
  }

  const noLock = !isNew(reportage) && (lock === undefined || lock.nickname !== userNickname)

  const headerActions = useMemo(() => {
    const moreMenuChildren = []

    if (!isNew(reportage)) {
      moreMenuChildren.push(
        <Menu.SubMenu key="print" title={t('words:print')}>
          <Menu.Item key="one-column">{t('main:printOneColumn')}</Menu.Item>
          <Menu.Item key="two-columns">{t('main:printTwoColumns')}</Menu.Item>
        </Menu.SubMenu>,
        <Menu.SubMenu key="view" title={t('words:view')}>
          <Menu.Item key="revisions" onClick={versionsHandler}>
            {t('words:versions')}
          </Menu.Item>
          <Menu.Item key="history" onClick={() => setHistoryVisible(true)}>
            {t('words:history')}
          </Menu.Item>
        </Menu.SubMenu>,
        <Menu.Divider key="divider" />,
      )

      if (canAdmin) {
        if (ok) {
          moreMenuChildren.push(<Menu.Item key="disapprove">{t('words:disapprove')}</Menu.Item>)
        } else {
          moreMenuChildren.push(<Menu.Item key="approve">{t('words:approve')}</Menu.Item>)
        }
      }
    }

    const moreMenu = <Menu onClick={menuClick}>{moreMenuChildren}</Menu>

    return [
      moreMenuChildren.length > 0 ? (
        <Dropdown key="more" overlay={moreMenu}>
          <Button>
            {t('main:more')} <DownOutlined />
          </Button>
        </Dropdown>
      ) : null,
      <Tooltip title="Ctrl+Alt+C">
        <Button key="close" onClick={checkBeforeClose} disabled={saving}>
          {t('words:close')}
        </Button>
      </Tooltip>,
      <Tooltip title="Ctrl+S">
        <Button key="save" type="primary" htmlType="submit" loading={saving} disabled={noLock}>
          {t('words:save')}
        </Button>
      </Tooltip>,
    ]
  }, [canAdmin, checkBeforeClose, menuClick, noLock, ok, reportage, saving, t, versionsHandler])

  return (
    <React.Fragment>
      {!lock && !isNew(reportage) ? (
        <Alert type="warning" message={t('document:lockLost')} style={{ marginBottom: 8 }} />
      ) : null}
      {lock && lock.nickname !== userNickname ? (
        <Alert
          type="warning"
          message={t('document:lockedTo', { nickname: lock.nickname })}
          style={{ marginBottom: 8 }}
        />
      ) : null}

      <Form
        name="reportage_form"
        form={form}
        onFinish={finishHandler}
        onFinishFailed={finishFailedHandler}
      >
        <div style={{ backgroundColor: theme.backgrounds.body }}>
          <ReportageFormHeader
            form={form}
            classifications={classifications}
            companies={companies}
            documentConfig={documentConfig}
            reportageConfig={reportageConfig}
            editors={editors}
            editorials={editorials}
            accessiblePrograms={accessiblePrograms}
            reporters={reporters}
            vehicles={vehicles}
            showSubtitle={textType}
            actions={headerActions}
            onReporterChange={setReporterId}
            onVehiclesChange={setVehiclesIds}
            onProgramsChange={programsChanged}
          />

          {(tvType || radioType) && (
            <ButtonBar style={{ marginTop: 16 }}>
              <HGroup style={{ flexGrow: 1, justifyContent: 'center' }}>
                <Button.Group>
                  {tvType && (
                    <React.Fragment>
                      <BarButton onClick={() => addSection(ART)}>{t('reportage:art')}</BarButton>
                      <BarButton onClick={() => addSection(OFF)}>{t('reportage:off')}</BarButton>
                    </React.Fragment>
                  )}
                  <BarButton onClick={() => addSection(APPEARANCE)}>
                    {tvType ? t('reportage:appearance') : t('reportage:loc')}
                  </BarButton>
                  <BarButton onClick={() => addSection(SOUND_UP)}>
                    {t('reportage:soundup')}
                  </BarButton>
                  <BarButton onClick={() => addSection(INTERVIEW)}>
                    {t('reportage:interview')}
                  </BarButton>
                </Button.Group>
                <Divider type="vertical" />
                <Button.Group>
                  {!visibleTexts[TEASER] && (
                    <BarButton onClick={() => addText(TEASER)}>{t('reportage:teaser')}</BarButton>
                  )}
                  {!visibleTexts[HEADING] && (
                    <BarButton onClick={() => addText(HEADING)}>
                      {t('reportage:heading_small')}
                    </BarButton>
                  )}
                  {!visibleTexts[NOTE] && (
                    <BarButton onClick={() => addText(NOTE)}>
                      {t('reportage:footnote_small')}
                    </BarButton>
                  )}
                  {!visibleTexts[INFORMATION] && (
                    <BarButton onClick={() => addText(INFORMATION)}>
                      {t('reportage:info')}
                    </BarButton>
                  )}
                  {visibleCgs && (
                    <BarButton onClick={() => openCGSelection()}>{t('program:cg')}</BarButton>
                  )}
                </Button.Group>
              </HGroup>
              <div style={{ marginRight: 8 }}>Total: {total}</div>
            </ButtonBar>
          )}
        </div>

        <ScrollContainer style={{ overflow: 'hidden auto' }}>
          {cgs.length > 0 && (
            <Collapse
              defaultActiveKey={defaultKey}
              css={css`
                margin-top: 8px;

                .ant-collapse-item .ant-collapse-header {
                  padding: 8px;
                  padding-left: 40px;
                }
              `}
            >
              <Panel header={t('reportage:cgs')} key={CGS_KEY}>
                <DragDropContext onDragEnd={cgDragEndHandler}>
                  <Droppable droppableId="droppableCG">
                    {droppableProvided => (
                      <div ref={droppableProvided.innerRef}>
                        {cgs.map((cg, index) => (
                          <Draggable key={cg.uuid} draggableId={`${cg.uuid}`} index={index}>
                            {draggableProvided => (
                              <div key={cg.uuid}>
                                <ReportageCGForm
                                  innerRef={draggableProvided.innerRef}
                                  provided={draggableProvided}
                                  key={cg.uuid}
                                  cg={cg}
                                  lastIndex={cgs.length - 1}
                                  onEdit={onEditCG}
                                  onRemove={onRemoveCG}
                                  onMove={onMoveCG}
                                  onDuplicate={onDuplicateCG}
                                  onFieldChange={onFieldChangeCG}
                                />
                                {cgs.length - 1 !== index && (
                                  <Divider style={{ margin: '8px 0' }} />
                                )}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Panel>
            </Collapse>
          )}

          <ReportageFormBody
            {...visibleProps}
            sections={sections}
            style={bodyStyle}
            onToggleTextCase={toggleTextCase}
            onToggleSectionCase={toggleSectionCase}
            onRemoveText={removeText}
            onRemoveSection={removeSection}
            onSectionChange={sectionChange}
            onDurationChange={updateSectionDuration}
            onMoveSectionUp={onMoveSectionUp}
            onMoveSectionDown={onMoveSectionDown}
            onDragSection={onDragSection}
          />
        </ScrollContainer>
      </Form>

      {selectedCgDevice && showTemplateModal && (
        <SelectCGTemplateModal
          visible={!!showTemplateModal}
          cg={selectedCgDevice}
          onClose={() => setShowTemplateModal(false)}
          onSelect={(...templates: IITemplate[]) => onAddIICG(templates)}
        />
      )}

      <YesNoCancel
        visible={confirmVisible}
        onCancel={() => setConfirmVisible(false)}
        onNo={confirmNoHandler}
        onYes={confirmYesHandler}
        title={t('words:confirmation')}
        closable={false}
      >
        {t('unsaved:confirm')}
      </YesNoCancel>

      <CarouselModal
        content={versionsCards}
        visible={versionsVisible}
        title={t('words:versions')}
        onClose={() => setVersionsVisible(false)}
      />

      {historyVisible && (
        <ReportageHistoryDrawer
          visible={historyVisible}
          setVisible={setHistoryVisible}
          reportage={reportage}
          programs={accessiblePrograms.data}
        />
      )}
    </React.Fragment>
  )
}

export default connect(mapStateToProps, dispatchProps)(memo(ReportageForm, isEqual))
