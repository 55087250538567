import React, { lazy, memo, useEffect } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

import { addApiListener, removeApiListener, ApiListener } from '@anews/api'
import { Notification, ServerEventType, SubscriptionType } from '@anews/types'

import { useOnce } from '@anews/hooks'

import { AuthActions, BranchActions, NotificationActions } from '../redux/actions'
import { PropsMapper, RootState } from '../redux/reducers'

import { useSubscription } from '../hooks/useSubscription'

import { start, done } from '../utils/progress'

import { AuthenticatedRoute, UnauthenticatedRoute, Loading } from './router'

export const ADMIN_ROUTE = '/admin'
export const LOGIN_ROUTE = '/login'
export const MAIN_ROUTE = '/main'
export const PREVIEW_ROUTE = '/preview'

// const minDelay = 250
// const LazyAdmin = lazy(() =>
//   Promise.all([
//     import('./components/layout/admin/AdminContainer'),
//     new Promise(resolve => setTimeout(resolve, minDelay)),
//   ]).then(([moduleExports]) => moduleExports),
// )

const LazyAdmin = lazy(() => import('./admin/AdminContainer'))
const LazyMain = lazy(() => import('./main/MainContainer'))
const LazyLogin = lazy(() => import('./login/LoginScreen'))
const LazyPreview = lazy(() => import('./preview/PreviewScreen'))
const LazyNotFound = lazy(() => import('./router/NotFound'))

const useCallListener: ApiListener = {
  onInit() {
    start()
  },
  onSuccess(data: any) {
    done()
  },
  onFailure(cause: any) {
    done()
  },
}

type Props = PropsMapper<typeof mapStateToProps, typeof mapDispatchToProps>

function App({
  authenticated,
  version,
  verifying,
  userId,
  loadAuth,
  loadConfig,
  notifySuccess,
  notifyInfo,
  notifyWarning,
  notifyError,
  notifyEvent,
}: Props) {
  useOnce(loadConfig)
  useOnce(loadAuth)

  useEffect(() => {
    addApiListener(useCallListener)
    return () => removeApiListener(useCallListener)
  }, [])

  // Mostra versao do anews no titulo da pagina
  useEffect(() => {
    document.title = version ? `Arion ${/^4/.test(version) ? '(alpha)' : version}` : `Arion`
  }, [version])

  // Notificações globais do sistema
  useSubscription(SubscriptionType.NOTIFICATIONS, (notification: Notification) => {
    switch (notification.level) {
      case 'SUCCESS':
        notifySuccess(notification)
        break
      case 'INFO':
        notifyInfo(notification)
        break
      case 'WARNING':
        notifyWarning(notification)
        break
      case 'ERROR':
        notifyError(notification)
        break
      default:
        break
    }
  })

  // Notificações globais para lembrete dos eventos de calendario
  useSubscription(SubscriptionType.CALENDAR_EVENT, event => {
    if (authenticated) {
      switch (event.type) {
        case ServerEventType.REMINDER: {
          const { payload } = event
          if (userId && payload.usersIds?.includes(userId)) {
            notifyEvent({ event: payload })
          }
          break
        }
        default:
          break
      }
    }
  })

  return !verifying ? (
    <BrowserRouter>
      <Switch>
        <UnauthenticatedRoute
          path={LOGIN_ROUTE}
          component={Loading(LazyLogin)}
          authenticated={authenticated}
        />
        <AuthenticatedRoute
          path={ADMIN_ROUTE}
          component={Loading(LazyAdmin)}
          authenticated={authenticated}
        />
        <AuthenticatedRoute
          path={PREVIEW_ROUTE}
          component={Loading(LazyPreview)}
          authenticated={authenticated}
        />
        <AuthenticatedRoute
          path={MAIN_ROUTE}
          component={Loading(LazyMain)}
          authenticated={authenticated}
        />
        <Redirect from="/" to={authenticated ? '/main' : '/login'} exact />
        <Route component={Loading(LazyNotFound)} />
      </Switch>
    </BrowserRouter>
  ) : null
}

const mapStateToProps = (state: RootState) => ({
  authenticated: state.auth.authenticated,
  version: state.branch.config.data?.version,
  userId: state.auth.user?.id,
  verifying: state.auth.verifying,
})

const mapDispatchToProps = {
  loadAuth: AuthActions.loadAuth,
  loadConfig: BranchActions.loadConfig,
  notifySuccess: NotificationActions.notifySuccess,
  notifyInfo: NotificationActions.notifyInfo,
  notifyWarning: NotificationActions.notifyWarning,
  notifyError: NotificationActions.notifyError,
  notifyEvent: NotificationActions.notifyEvent,
}
export default connect(mapStateToProps, mapDispatchToProps)(memo(App))
