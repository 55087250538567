/* eslint-disable react/no-array-index-key */
import React from 'react'

import styled from '@emotion/styled'

import { RichTextBlock, RichTextLeaf } from '@anews/types'
import { highlight } from '@anews/utils'

import { richTextPalette } from './RichTextEditor'

interface Props {
  content?: RichTextBlock[]
}

const Leaf = styled.span<{
  bold: boolean
  italic: boolean
  color: string
  underline: boolean
  comment?: boolean
}>`
  font-weight: ${({ bold }) => (bold ? '700' : '400')};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  color: ${({ color, comment }) => (comment ? richTextPalette.comment : color)};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
`

/*
 * Os textos migrados antes do suporte a formatação que tinham quebra de linha
 * ficaram tudo dentro de um único RichTextBlock do tipo 'paragraph'.
 *
 * O editor de texto consegue converter isso e corrigir na primeira vez que
 * salvar, mas antes disso a visualização do texto quebrado fica errada.
 *
 * Essa função corrige isso, quebrando o texto com quebras de linhas em
 * múltiplos parágrafos.
 */
function fixMigratedContent(content?: RichTextBlock[]): RichTextBlock[] | undefined {
  if (content?.length === 1) {
    const [first] = content
    if (first.type === 'paragraph' && first.children.length === 1) {
      const [firstLeaf] = first.children
      const lines = firstLeaf.text.split('\n')
      return lines.map(line => ({ ...first, children: [{ ...firstLeaf, text: line }] }))
    }
  }
  return content
}

function RawTextViewer({ content }: Props) {
  return (
    <>
      {fixMigratedContent(content)?.map((element, elementIndex) => {
        if (element.type !== 'paragraph') {
          return null
        }

        const children = element.children as RichTextLeaf[]

        if (children.length === 1 && children[0].text === '') {
          return <br key={elementIndex} />
        }

        return (
          <p key={elementIndex}>
            {children.map((leaf, leafIndex) => (
              <Leaf key={leafIndex} {...leaf} comment={/(\[+[^\]]*\])/g.test(leaf.text ?? '')}>
                {highlight(leaf.text)}
              </Leaf>
            ))}
          </p>
        )
      })}
    </>
  )
}

export default React.memo(RawTextViewer)
